import classNames from "classnames";
import sum from "lodash/sum";
import truncate from "lodash/truncate";
// import uniq from "lodash/uniq";
import { useCallback, useState } from "react";
import { highlightSearchResultText } from "../tools/highlightSearchResultText";
// import { useFetchSearch } from "./useFetchSearch";
import { MetadataViewAsTags } from "./MetadataViewAsTags";
import { Collapsed } from "./Collapsed";
import { Markdown } from "../../../components/Markdown";
import RoveFocusItem from "./RoveFocusItem";
import { onResultsItemClicked } from "./onResultsViewChanged";
import uniqBy from "lodash/uniqBy";
// import useRoveFocus from "./useRoveFocus";
// import RoveFocusItem from "./RoveFocusItem";

interface DocumentMetadata {
    source?: string;
    dataset?: string;
    filename?: string;
    source_url: string;
    source_url_anchor?: string;
    title?: string;
    summary?: string;
    device_object?: string;
    device_model?: string;
    catalog_level_0?: string;
    catalog_level_1?: string;
    catalog_level_2?: string;
    catalog_level_3?: string;
    catalog_level_4?: string;
}

interface Document {
    page_content: string;
    metadata: DocumentMetadata | { [key: string]: string };
    type?: string;
}

interface SubGroup {
    name: string;
    documents: Document[];
    source_url?: string;
}

export interface DataItem {
    name: string;
    subGroups: SubGroup[];
    source_url?: string;
    canBeSkipped?: boolean;
}

interface DataItemWithSkipped {
    name: string;
    skipped: DataItem[];
}

export interface ResultsViewProps {
    dataset_id: string;
    searchParamValue?: string;
    renderEmpty?: any;
    isContentWithMarkdown?: boolean;
    filterEnabled?: boolean;
    fullMatchEnabled?: boolean;
    isMetadataVisible?: boolean;
    ui?: {
        show_full_metadata_button?: boolean;
    },
    isLoading?: boolean;
    data?: DataItem[];
    error?: any;
    view?: string;
    onItemPress?: (item: any) => void;
    itemPressLabel?: string;
    focus?: any;
    setFocus?: any;

    renderInRoveFocusItem?: any;
}

const MIN_VALUE_TO_COLLAPSE_BY_INSIDE_COUNT = 5;
const MIN_VALUE_TO_COLLAPSE_BY_ALL_RESULTS_COUNT = 10;

interface ResultItemProps {
    _e: DataItem;
    isOpened?: boolean;
    searchText?: string;
    className?: string;
    coundInside?: number;
    view?: string;
    isContentWithMarkdown?: boolean;
    ui?: {
        show_metadata?: boolean;
        show_full_metadata_button?: boolean;
        show_metadata_whitelist?: string[];
    },
    onItemPress?: (item: any) => void;
    handleAnalyticsPress?: (item: any) => void;
}

interface ResultItemWithSkippedProps extends ResultItemProps {
    resss?: DataItemWithSkipped[];
}

export const ResultItem: React.FC<ResultItemProps> = ({
    _e,
    isOpened,
    searchText,
    className,
    coundInside,
    view = "basic",
    isContentWithMarkdown,
    ui,
    onItemPress,
    handleAnalyticsPress,
}) => {
    const [isContentCollapsed, setContentCollapsed] = useState(
        (_e.subGroups || []).length > 4
    );
    const subtitle = _e.subGroups?.map((e) => e.name).slice(0, 3);
    const showTruncatedIndicator = _e.subGroups?.length > subtitle.length;

    const subGroups = isContentCollapsed
        ? _e.subGroups?.slice(0, 3)
        : _e.subGroups;
    const subGroupsAfterLen = _e.subGroups?.length - subGroups?.length;

    const renderCollapsed = useCallback(() => (
        <li
            className={classNames(
                "bg-[var(--enp-result-item-background)] rounded-xl border border-[var(--enp-result-item-border)] pb-2 md:pb-0",
                className
            )}
        >
            <Collapsed
                defaultValue={isOpened}
                count={coundInside || _e.subGroups.length}
                renderVisibleChildren={(_isVisible) => (
                    <a
                        title={`Go to ${_e.name} page`}
                        target="_blank"
                        rel="noreferrer"
                        href={_e.subGroups[0]?.documents[0]?.metadata?.source_url}
                        onClick={() => handleAnalyticsPress?.(_e)}
                        className="relative flex gap-1 flex-col text-[var(--enp-result-item-title-color)] hover:text-[var(--enp-result-item-title-hover-color)] px-[var(--enp-result-item-padding-x)] py-[var(--enp-result-item-padding-y)]"
                    >
                        <p className="font-semibold text-lg break-keep">
                            {highlightSearchResultText(_e.name, searchText)}
                        </p>
                        <p
                            className={classNames(
                                "text-xs font-light text-[var(--enp-result-item-subtitle-color)] pr-10 flex flex-wrap gap-x-3 gap-y-1",
                                _isVisible ? "md:invisible" : ""
                            )}
                        >
                            {subtitle.map((s, indx) => (
                                <span
                                    key={s + indx}
                                    className="bg-[var(--enp-result-item-subtitle-background)] rounded"
                                >
                                    {highlightSearchResultText(
                                        s,
                                        searchText,
                                        "text-[var(--enp-result-item-subtitle-color)] font-semibold"
                                    )}
                                </span>
                            ))}
                            {showTruncatedIndicator && <span>...</span>}
                        </p>
                    </a>
                )}
            >
                {_e.subGroups.length > 0 && (
                    <ul className="flex flex-col gap-2 mt-2 px-3">
                        {_e.subGroups.map((subGroup, index) => {
                            return (
                                <li
                                    key={subGroup.name + index}
                                    className={classNames(
                                        "flex flex-col md:flex-row md:gap-4 pt-4 md:pt-0 md:py-2 md:pb-3"
                                    )}
                                >
                                    <div className="w-full flex-shrink-0 max-w-[200px]">
                                        <p className="mb-2 text-sm font-semibold break-keep text-[var(--enp-text-gray)]">
                                            {highlightSearchResultText(subGroup.name, searchText)}
                                        </p>
                                    </div>
                                    {subGroup.documents.length > 0 && (
                                        <ul className="flex flex-col flex-grow gap-4">
                                            {subGroup.documents.map((sd, sdIndx) => {
                                                const hrefLink = sd.metadata.source_url_anchor
                                                    ? `${sd.metadata?.source_url}#${sd.metadata.source_url_anchor}`
                                                    : sd.metadata.source_url;

                                                const renderLinkOrButton = (child: any) => {
                                                    if (onItemPress) {
                                                        return (
                                                            <button
                                                                onClick={() => {
                                                                    onItemPress(sd)
                                                                    handleAnalyticsPress?.(_e)
                                                                }}
                                                                className={classNames(
                                                                    "text-left bg-[var(--enp-subgroup-result-item-background)] p-3 px-4 block rounded-md text-sm text-[var(--enp-text-gray)] hover:text-[var(--enp-text-gray)] border border-[var(--enp-subgroup-result-item-border-color)] hover:border-[var(--enp-subgroup-result-item-border-hover-color)]"
                                                                )}
                                                            >
                                                                {child}
                                                            </button>
                                                        )
                                                    }

                                                    return (
                                                        <a
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            href={hrefLink}
                                                            onClick={() => handleAnalyticsPress?.(_e)}
                                                            className={classNames(
                                                                "bg-[var(--enp-subgroup-result-item-background)] p-3 px-4 block rounded-md text-sm text-[var(--enp-text-gray)] hover:text-[var(--enp-text-gray)] border border-[var(--enp-subgroup-result-item-border-color)] hover:border-[var(--enp-subgroup-result-item-border-hover-color)]"
                                                            )}
                                                        >
                                                            {child}
                                                        </a>
                                                    )
                                                }

                                                return (
                                                    <li key={sd.page_content + sdIndx}>
                                                        {renderLinkOrButton(
                                                            <>
                                                                {sd.metadata.catalog_level_2 && (
                                                                    <p className="font-medium mb-1">
                                                                        {highlightSearchResultText(
                                                                            sd.metadata.catalog_level_2,
                                                                            searchText
                                                                        )}
                                                                    </p>
                                                                )}
                                                                {sd.metadata.catalog_level_3 && (
                                                                    <p className="font-medium mb-1">
                                                                        {highlightSearchResultText(
                                                                            sd.metadata.catalog_level_3,
                                                                            searchText
                                                                        )}
                                                                    </p>
                                                                )}
                                                                {sd.metadata.catalog_level_4 && (
                                                                    <p className="font-medium mb-1">
                                                                        {highlightSearchResultText(
                                                                            sd.metadata.catalog_level_4,
                                                                            searchText
                                                                        )}
                                                                    </p>
                                                                )}
                                                                <p className="text-sm block">
                                                                    {isContentWithMarkdown ? (
                                                                        <Markdown searchValue={searchText}>
                                                                            {sd.page_content}
                                                                        </Markdown>
                                                                    ) : (
                                                                        highlightSearchResultText(
                                                                            sd.page_content,
                                                                            searchText
                                                                        )
                                                                    )}
                                                                </p>
                                                            </>
                                                        )}


                                                        {ui?.show_metadata && (
                                                            <div className="mt-1">
                                                                <MetadataViewAsTags
                                                                    data={sd.metadata}
                                                                    searchValue={searchText}
                                                                    showFullButton={ui?.show_full_metadata_button}
                                                                    fieldsWhiteList={ui?.show_metadata_whitelist}
                                                                />
                                                            </div>
                                                        )}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                )}
            </Collapsed>
        </li>
    ), [_e, className, coundInside, handleAnalyticsPress, isContentWithMarkdown, isOpened, onItemPress, searchText, showTruncatedIndicator, subtitle, ui?.show_full_metadata_button, ui?.show_metadata, ui?.show_metadata_whitelist]);

    const simpleItemClassName = "flex flex-col min-h-10 justify-center text-[var(--enp-result-item-title-color)] hover:text-[var(--enp-result-item-title-hover-color)] text-sm font-medium bg-[var(--enp-result-item-background)] border border-[var(--enp-border-color)] rounded-lg py-1 px-3"
    const renderSimple = useCallback(() => (
        <div className="flex flex-col md:flex-row gap-2 border-b border-b-[var(--enp-border-color)] pb-4 last:border-b-0">
            <a
                title={`Go to ${_e.name} page`}
                target="_blank"
                rel="noreferrer"
                href={_e.source_url}
                onClick={() => handleAnalyticsPress?.(_e)}
                className="relative flex min-h-10 justify-center md:min-h-auto md:justify-normal md:flex-[0.3] gap-1 flex-col text-[var(--enp-result-item-title-color)] hover:text-[var(--enp-result-item-title-hover-color)] "
            >
                <p className="font-semibold text-base break-keep">
                    {highlightSearchResultText(_e.name, searchText)}
                </p>
            </a>
            <ul className="md:flex-[0.7] flex flex-col gap-2">
                {subGroups.map((e) => {
                    return (
                        <li key={e.name} className={classNames("flex flex-col gap-2")}>
                            <a
                                rel="noreferrer"
                                target="_blank"
                                href={e.source_url}
                                onClick={() => handleAnalyticsPress?.({ _from: "subgroups", e })}
                                className={simpleItemClassName}
                            >
                                <span className="py-0.5">{highlightSearchResultText(e.name, searchText)}</span>
                            </a>
                            <div className="flex flex-col text-xs">
                                {uniqBy(e.documents.slice(0, 3).map(e => e.metadata), item => `${item.catalog_level_2}-${item.catalog_level_3}-${item.catalog_level_4}`).map(doc_metadata => {
                                    const { catalog_level_2, catalog_level_3, catalog_level_4 } = doc_metadata;
                                    const result_title = catalog_level_4 || catalog_level_3 || catalog_level_2 || null;

                                    if (e.name === catalog_level_2 || e.name === catalog_level_3) return null;
                                    if (!result_title) return null;

                                    const catalogPath = [catalog_level_2, catalog_level_3, catalog_level_4]
                                        .filter(Boolean)
                                        .join(" › ");

                                    return (
                                        <a
                                            key={`${catalog_level_4}${catalog_level_3}${catalog_level_2}`}
                                            href={`${doc_metadata?.source_url}#${doc_metadata.source_url_anchor || ""}`}
                                            target="_blank"
                                            rel="noreferrer"
                                            onClick={() => handleAnalyticsPress?.({ _from: "subdocuments", doc_metadata })}
                                            className="hover:bg-background1Hover px-4 py-1 rounded-lg text-textGray hover:text-[var(--enp-result-item-title-hover-color)]"
                                        >

                                            {highlightSearchResultText(truncate(catalogPath, { length: 68 }), searchText, "text-text font-medium")}
                                        </a>
                                    )
                                })}
                            </div>

                        </li>
                    );
                })}
                {isContentCollapsed && (subGroupsAfterLen || 0) > 0 && (
                    <button
                        className="text-xs font-medium min-h-8 flex items-center text-[var(--enp-text-gray)] hover:text-[var(--enp-result-item-title-hover-color)]"
                        onClick={() => setContentCollapsed(!isContentCollapsed)}
                    >
                        show +{subGroupsAfterLen} related topics
                    </button>
                )}
            </ul>
        </div>
    ), [_e, handleAnalyticsPress, isContentCollapsed, searchText, subGroups, subGroupsAfterLen]);


    if (_e.source_url?.endsWith(".pdf")) {
        const _filepath = _e.source_url?.split("/");
        const _filename = _filepath[_filepath.length - 1];
        return (
            <a
                className="flex gap-2 items-center py-2 rounded-lg text-[var(--enp-result-item-title-color)] group hover:text-[var(--enp-result-item-title-hover-color)]"
                href={_e?.source_url}
                target="_blank"
                rel="noreferrer"
            >
                <i className="material-icons-outlined text-3xl group-hover:text-[var(--enp-result-item-title-hover-color)] text-textGray">description</i>
                <p className="font-semibold text-sm">
                    {_filename}
                </p>
                <p></p>
            </a>
        )
    }

    return view === "basic" ? renderSimple() : renderCollapsed();
};

const ResultItemWithSkipped: React.FC<ResultItemWithSkippedProps> = ({
    resss,
    _e,
    isOpened,
    searchText,
    coundInside,
    view,
    isContentWithMarkdown,
    ui,
    onItemPress,
    handleAnalyticsPress,
}) => {
    const [isHidden, setHidden] = useState(true);
    const foundInSkipped = resss?.filter((e) => e.name === _e.name)?.[0];
    const show = () => {
        setHidden(!isHidden);
    };
    return (
        <>
            <ResultItem
                _e={_e}
                isOpened={isOpened}
                searchText={searchText}
                coundInside={coundInside}
                view={view}
                isContentWithMarkdown={isContentWithMarkdown}
                ui={ui}
                onItemPress={onItemPress}
                handleAnalyticsPress={handleAnalyticsPress}
            />
            {!!foundInSkipped?.skipped?.length && (
                <>
                    <p className="text-xs text-[var(--enp-text-gray)] px-4 font-light">
                        {foundInSkipped.skipped.length} similar results hidden.{" "}
                        <button
                            type="button"
                            onClick={show}
                            className="inline italic rounded hover:text-textLink2"
                        >
                            {isHidden ? "Show" : "Hide"} all
                        </button>
                    </p>
                    {!isHidden &&
                        foundInSkipped?.skipped.map((__e, index) => {
                            const key = __e.name + index + isOpened;
                            return (
                                <ResultItem
                                    key={key}
                                    _e={__e}
                                    isOpened={false}
                                    searchText={searchText}
                                    className="ml-4"
                                    view={view}
                                    isContentWithMarkdown={isContentWithMarkdown}
                                    ui={ui}
                                    onItemPress={onItemPress}
                                    handleAnalyticsPress={handleAnalyticsPress}
                                />
                            );
                        })}
                </>
            )}
        </>
    );
};

// const analytics = async () => {
//     await onResultsItemClicked({
//         dataset_id: "any",
//         element_id: "ada",
//         source_url: "ada"
//     })
// }

export const ResultsView = ({
    searchParamValue,
    renderEmpty,
    isContentWithMarkdown,
    ui,
    isLoading,
    data,
    view,
    error,
    onItemPress,
    focus,
    setFocus,
    renderInRoveFocusItem,
}: ResultsViewProps) => {



    // const roveFocusLength = data?.length || 0;
    // const { focus, setFocus, resetFocus } = useRoveFocus(roveFocusLength, {
    //     didFocusOnStartAgain: () => {
    //         // inputRef.current?.focus()
    //     },
    // });

    const handleAnalyticsPress = useCallback(async (item: any) => {
        // console.log("item", item)
        await onResultsItemClicked({
            dataset_id: "handbook_search_dataset",
            title: item?.name,
            element_id: item?.index || 0,
            source_url: item?.source_url
        })
    }, []);

    if (isLoading) {
        return (
            <div>
                <p>Loading...</p>
            </div>
        );
    }


    if (error) {
        return (
            <div>
                <p>Error: {error}</p>
            </div>
        );
    }

    if (!data || !searchParamValue) {
        if (renderEmpty) {
            return renderEmpty;
        }

        return null;
    }

    const renderData = () => {
        if (isLoading) return null;

        function getDataWithSkipped(_data: any) {
            const results: DataItemWithSkipped[] = [];
            let currentGroup: DataItemWithSkipped | null = null;

            for (const item of _data) {
                if (!item.canBeSkipped) {
                    if (currentGroup) {
                        results.push(currentGroup); // Push the previous group if there was one being built
                    }
                    currentGroup = { name: item.name, skipped: [] }; // Start a new group
                } else if (currentGroup) {
                    currentGroup.skipped.push(item); // Add skipped items to the current group
                }
            }

            // Don't forget to add the last group if the loop ends and there's an open group being built
            if (currentGroup && currentGroup.skipped.length > 0) {
                results.push(currentGroup);
            }

            return results;
        }

        const dataWithSkipped = getDataWithSkipped(data);

        return data?.map((_e, index) => {
            if (_e.canBeSkipped) {
                return null;
            }

            let coundInside = _e?.subGroups ? sum(_e.subGroups.map((__e) => __e.documents.length)) : 0;

            const isOpenedByDefault =
                (index !== 0 && coundInside <= MIN_VALUE_TO_COLLAPSE_BY_INSIDE_COUNT) ||
                    data?.length > MIN_VALUE_TO_COLLAPSE_BY_ALL_RESULTS_COUNT
                    ? false
                    : coundInside <= MIN_VALUE_TO_COLLAPSE_BY_INSIDE_COUNT;
            // for isOpenedByDefault instead of false
            // _e.subGroups.length <= MIN_VALUE_TO_COLLAPSE || index === 0;
            const key = _e.name + index + isOpenedByDefault;

            return (
                <RoveFocusItem
                    key={key}
                    focus={focus === index}
                    index={index}
                    setFocus={setFocus}>
                    <div className="focus:ring-2 focus:ring-borderFocus ring-offset-4 ring-offset-background1 rounded outline-none" onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            (window as any).open(_e.subGroups[0]?.documents[0]?.metadata.source_url, '_blank').focus();

                            handleAnalyticsPress({ ..._e, index, target: "via_press_enter" })
                        }
                    }}>
                        <ResultItemWithSkipped
                            _e={_e}
                            key={key}
                            resss={dataWithSkipped}
                            searchText={searchParamValue}
                            isOpened={isOpenedByDefault}
                            coundInside={coundInside}
                            view={view}
                            isContentWithMarkdown={isContentWithMarkdown}
                            ui={ui}
                            onItemPress={onItemPress}
                            handleAnalyticsPress={handleAnalyticsPress}
                        />
                    </div>
                </RoveFocusItem>
            );
        });
    };

    return (
        <>
            {data?.length ? (
                <ul
                    className={classNames(
                        "flex flex-col gap-2 whitespace-pre-wrap break-all",
                        isLoading && "opacity-50"
                    )}
                >
                    {renderInRoveFocusItem}
                    {!isLoading ? renderData() : null}
                </ul>
            ) : (
                <div>
                    Unfortunately, nothing is found.
                    <br /> Please supplement your request with additional information.
                </div>
            )}
        </>
    );
};
